export var COMMONS =  {
        dhm(second) {
            let result = "";
            let d = Math.floor(second / (60 * 60 * 24));

            if (d > 0) {
                result += (d + "日")
                second = second % (60 * 60 * 24);
            }
            let h = Math.floor(second / (60 * 60));
            if (h > 0) {
                result += (h + "時間");
                second = second % (60 * 60);
            }
            let m = Math.floor(second / (60));
            if (m > 0) {
                result += (m + "分");
            }
            // console.log(d, h, m)
            return result + "間";
        },
        //Y軸のフィット(分割を正確にするため)を調整する
        getYmin3(max, min, unit) {
            let gap = max - min;
            if(gap % unit == 0){
                return min;
            }
            return min - (unit - (gap % unit));
        }
};

export var GOOD_MOUNT_FUNCTION = function (vm) {
    document.querySelector('.magnifying-glass').addEventListener('click', function () {
        vm.goodPaging();//虫眼鏡を押された
    });
    document.querySelector("input.vgt-input.vgt-pull-left").addEventListener('keyup', function (event) {
        vm.pagingParams.keyword = event.target.value;//検索欄に入力下
    });
}


// export var COMMON_FUN = function () {
//     return {
//         FIND_COLUMN_INDEX:function(columns,fieldName){
//             return columns.findIndex(function(col){
//                 return col.field == fieldName;
//             });
//         }
//     };
// }